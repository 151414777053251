import { axiosInstance } from "../config/axiosInstance";
import { showSnackbar } from "../../utilis/snackbar";
import { covertJSONToXFormData } from "../../utilis/covert-to-form-data";
import { AxiosResponse } from "axios";
import {
  LogoutPayload,
  RefreshTokenPayload,
  TokenPayload,
  TokenResponse,
} from "../../interfaces/Auth";

const client_id = process.env.REACT_APP_CLIENT_ID_ADMIN_DASHBOARD;
const redirect_uri = process.env.REACT_APP_LOGIN_REDIRECT_URL_ADMIN_DASHBOARD;
const KEYCLOAK_REALM = "admin";
const TOKEN_ROUTE = "/token";
const LOGOUT_API = "/logout";

const generateState = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const checkAuth = (status?: number) => {
  const response_type = "code";
  // State will be generated if user not authenticated or has no access token
  const isAuth = localStorage.getItem("access_token");
  if (!isAuth || status === 401) {
    const generatedState = generateState();
    localStorage.setItem("state", generatedState);
    const AuthRedirectionURI = `${process.env.REACT_APP_KEYCLOCK_BASE_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/auth?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&state=${generatedState}`;
    // Redirect user to centeral platform to login
    window.location.href = AuthRedirectionURI;
  }
};

export const tokenExchange = (
  code: string | null,
  userState: string | null,
): Promise<AxiosResponse<TokenResponse>> => {
  if (localStorage.getItem("state") === userState) {
    const data: TokenPayload = {
      code,
      client_id,
      redirect_uri,
      grant_type: "authorization_code",
    };

    return axiosInstance({
      method: "POST",
      url: `/authv2/${KEYCLOAK_REALM}${TOKEN_ROUTE}`,
      data: covertJSONToXFormData(data),
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  } else {
    showSnackbar(
      "We had trouble verifying you, please clear your cache and try again",
      "error",
    );
  }
};

export const logout = (): Promise<AxiosResponse<void>> => {
  const data: LogoutPayload = {
    client_id,
    refresh_token: localStorage.getItem("refresh_token"),
  };

  return axiosInstance({
    method: "POST",
    url: `/authv2/${KEYCLOAK_REALM}${LOGOUT_API}`,
    data: covertJSONToXFormData(data),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};

export const refreshToken = (): Promise<AxiosResponse<TokenResponse>> => {
  const data: RefreshTokenPayload = {
    client_id,
    grant_type: "refresh_token",
    refresh_token: localStorage.getItem("refresh_token"),
  };

  return axiosInstance({
    method: "POST",
    url: `/authv2/${KEYCLOAK_REALM}${TOKEN_ROUTE}`,
    data: covertJSONToXFormData(data),
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
  });
};
