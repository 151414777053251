export const node_types = {
  ROOT_NODE: "root_node",
  CHILD_NODE: "child_node",
  SITE_NODE: "site_node",
};

export const period_interval = [
  {
    id: 1,
    label: "Seconds",
    value: 1,
  },
  {
    id: 2,
    label: "Minutes",
    value: 60,
  },
  {
    id: 3,
    label: "Hours",
    value: 60 * 60,
  },
  {
    id: 4,
    label: "Days",
    value: 60 * 60 * 24,
  },
];

export const conditions = {
  GREATER_EQUAL: ">=",
  GREATER_THAN: ">",
  LESS_THAN: "<",
  LESS_EQUAL: "<=",
  EQUAL: "==",
  NOT_EQUAL: "!=",
};

export const integrationTypes = {
  SMS: "sms",
  VOICE: "voice",
  EMAIL: "email",
  SPILL_APP: "spill_app",
};

export const resolution_types = [
  {
    id: 1,
    name: "True positive",
  },
  {
    id: 2,
    name: "False positive",
  },
];

export const rule_types = {
  RULE_INCIDENT_CREATED_COUNT: "rule_incident_created_count",
  RULE_INCIDENT_OPEN_DURATION: "rule_incident_open_duration",
};

export const activeOptions = [
  {
    id: 1,
    value: true,
    name: "Active",
  },
  {
    id: 2,
    value: false,
    name: "Cancelled",
  },
  {
    id: 3,
    value: null,
    name: "All",
  },
];
