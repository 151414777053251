import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { Field, Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppSelector } from "../../../../hooks/useStoreHook";
import MUISelect from "../../../Controls/Select";
import { Customer, CustomerPayload } from "../../../../interfaces/User";
import { FlatOrgStructureNode } from "../../../../interfaces/OrgStructure";
import MultiSelect from "../../../Controls/MultiSelect";
import AssignPermission from "./AssignPermission";
import AssignLicense from "./AssignLicense";

interface AddUserFormProps {
  handleSubmit: (values: CustomerPayload) => void;
  parentNodes: FlatOrgStructureNode[];
  customer: Customer;
}

export default function EditUserForm({
  customer,
  handleSubmit,
  parentNodes,
}: Readonly<AddUserFormProps>) {
  const params = useParams();
  const navigate = useNavigate();
  const isLoading = useAppSelector((state) => state.loader.isLoading);
  const schema = yup.object().shape({
    username: yup.string().required("Required"),
    email: yup.string().required("Required").email("Email format is required"),
    first_name: yup.string().required("Required"),
    last_name: yup.string().required("Required"),
    permission: yup.array().nullable(),
    licences: yup.array().nullable(),
    org_structure_assignments: yup.array().of(yup.string()).nullable(),
  });
  return (
    <Formik
      initialValues={{
        username: customer.username || "",
        email: customer.email || "",
        first_name: customer.firstName || "",
        last_name: customer.lastName || "",
        permission: [],
        licences: [],
        org_structure_assignments: customer.org_structure_assignments
          ? customer.org_structure_assignments[0]
          : "",
      }}
      validationSchema={schema}
      enableReinitialize
      onSubmit={(values: any) => handleSubmit(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <Box component={"h5"} mb={0}>
            Username
          </Box>
          <Box component={"p"} mt={1}>
            {values.username}
          </Box>
          <TextField
            type="email"
            id="email"
            label="Email*"
            fullWidth
            margin="normal"
            name="email"
            error={Boolean(errors.email && touched.email)}
            helperText={errors.email && touched.email && errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            size="small"
          />
          <TextField
            type="text"
            id="first_name"
            label="First Name*"
            fullWidth
            margin="normal"
            name="first_name"
            error={Boolean(errors.first_name && touched.first_name)}
            helperText={
              errors.first_name && touched.first_name && errors.first_name
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.first_name}
            size="small"
          />
          <TextField
            type="text"
            id="last_name"
            label="Last Name*"
            fullWidth
            margin="normal"
            name="last_name"
            error={Boolean(errors.last_name && touched.last_name)}
            helperText={
              errors.last_name && touched.last_name && errors.last_name
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            size="small"
          />
          <Box my={2}>
            <Box component="h4" mb={0}>
              Organisation Assignments
            </Box>
            <Typography color="error" className="text-danger">
              * Mobile application notifications will not reflect this change until the refresh token has updated, which takes up to 12 hours. To speed up the process, ask the mobile application user to log out then back in 
            </Typography>
            <Typography color="error" className="text-danger">
              * A users tree menu will not update to reflect this change until access token has updated, which takes up to 15 mins.
            </Typography>
            <MUISelect
              value={values.org_structure_assignments || ""}
              label={"Parent Node"}
              name="org_structure_assignments"
              handleChange={handleChange}
              handleBlur={handleBlur}
              keyItem="uuid"
              keyValue="name"
              list={parentNodes}
              labelId={"label-parent_node_uuid"}
              id={"select-parent_node_uuid"}
              styles={{ mt: 2 }}
              setFieldValue={setFieldValue}
              renderValue={() => {
                const parentNode = parentNodes?.find(
                  (node) => node.uuid === values.org_structure_assignments,
                );
                return <>{parentNode?.name}</>;
              }}
            />
          </Box>
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12} sm={6}>
              <LoadingButton
                loading={isLoading}
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </LoadingButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() =>
                  navigate(-1)
                }
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
