export const redirectionURLs = [
  {
    name: "Spill dashboard",
    redirect_uri: process.env.REACT_APP_REDIRECT_URL_SPILL_DASHBOARD,
    client_id: process.env.REACT_APP_CLIENT_ID_SPILL_DASHBOARD,
  },
  {
    name: "Spill mobile app",
    redirect_uri: process.env.REACT_APP_LOGIN_REDIRECT_URL_SPILL_MOBILE_APP,
    client_id: process.env.REACT_APP_CLIENT_ID_SPILL_MOBILE_APP,
  },
  {
    name: "Central platform",
    redirect_uri: process.env.REACT_APP_LOGIN_REDIRECT_URL_CENTRAL_PLATFORM,
    client_id: process.env.REACT_APP_CLIENT_ID_CENTRAL_DASHBOARD,
  },
];
