/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import CameraDetailsCard from "../../components/Cameras/v1/Camera/DetailsCard";
import CameraOverrides from "../../components/Settings/v1/CameraOverrides/ListTable";
import CameraConnectionList from "../CameraConnection/CameraConnectionList";
import { getCameraDetails, raiseIncident } from "../../apis/v1/Camera/Camera";
import { getCompanyCameraSettingsOverrides } from "../../apis/v1/Settings/CameraCompanyOverrides";
import { getCameraDefaultSettings } from "../../apis/v1/Settings/CameraDefaults";
import {
  getCameraSettings,
  getCameraSettingsOverrides,
} from "../../apis/v1/Settings/CameraOverrides";
import { getSiteCameraSettingsOverrides } from "../../apis/v1/Settings/CameraSiteOverrides";
import MUIBreadcrumb from "../../components/Breadcrumb";
import {
  addNewCameraImage,
  editCameraImage,
  getCameraImage,
  getCompanyCameraDetections,
} from "../../apis/v1/DetectionZone/CameraImage";
import { getDetectionZones } from "../../apis/v1/DetectionZone/DetectionZones";
import ImageTable from "../../components/CameraImage/ImageTable";
import { showSnackbar } from "../../utilis/snackbar";
import { AWAITING_SYNC_MSG } from "../../utilis/shared";

export default function CameraDetails() {
  const [camera, setCamera] = useState(null);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [cameraOverrides, setCameraOverrides] = useState([]);
  const [sitesOverrides, setSitesOverrides] = useState([]);
  const [companyCameraOverrides, setCompanyCameraOverrides] = useState(null);
  const [defaultCameraSettings, setDefaultCameraSettings] = useState(null);
  const [cameraSettings, setCameraSettings] = useState(null);
  const [cameraImage, setCameraImage] = useState(null);
  const [polygons, setPolygons] = useState(null);
  const imageRef = useRef<any>();

  const getCompanyOverridesRequest = () => {
    getCompanyCameraSettingsOverrides(params.company_uuid).then((res) => {
      if (res) {
        setCompanyCameraOverrides(res.data);
      }
    });
  };

  const getSiteOverridesRequest = () => {
    getSiteCameraSettingsOverrides(params.company_uuid, params.site_uuid).then(
      (res) => {
        if (res) {
          setSitesOverrides(res.data);
        }
      },
    );
  };
  const getCameraOverridesRequest = () => {
    getCameraSettingsOverrides(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
    ).then((res) => {
      if (res) {
        getCameraSettingsRequest();
        setCameraOverrides(res.data);
      }
    });
  };

  const getCameraSettingsRequest = () => {
    getCameraSettings(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
    ).then((res) => {
      if (res) {
        setCameraSettings(res.data);
      }
    });
  };

  const getCameraDetailsRequest = () => {
    getCameraDetails(
      params.company_uuid,
      params.site_uuid,
      params.camera_uuid,
    ).then((res) => {
      if (res) {
        setCamera(res.data);
      }
    });
  };

  const getCameraImageRequest = () => {
    getCompanyCameraDetections(params.company_uuid, params.camera_uuid).then(
      (res) => {
        if (res.data) {
          setCameraImage({
            ...cameraImage,
            uuid: res.data?.image?.uuid,
          });
          if (res.data?.image) {
            getCameraImage(
              params.company_uuid,
              params.camera_uuid,
              res.data.image.uuid,
            ).then((resp) => {
              if (resp) {
                setCameraImage({
                  ...cameraImage,
                  uuid: res.data?.image?.uuid,
                  url: window.URL.createObjectURL(resp.data),
                });
              }
            });
            getDetectionZones(
              params.company_uuid,
              params.camera_uuid,
              res.data.image.uuid,
            ).then((res) => {
              if (res) {
                setPolygons(res.data.length);
              }
            });
          }
        }
      },
    );
  };

  const getCameraImagePolygons = (image_uuid) => {
    getDetectionZones(params.company_uuid, params.camera_uuid, image_uuid).then(
      (res) => {
        if (res) {
          setPolygons(res.data.length);
        }
      },
    );
  };

  useEffect(() => {
    getCameraDetailsRequest();
    getCameraDefaultSettings().then((res) => {
      if (res) {
        setDefaultCameraSettings(res.data);
      }
    });
    getCompanyOverridesRequest();
    getSiteOverridesRequest();
    getCameraOverridesRequest();
    getCameraSettingsRequest();
    getCameraImageRequest();
  }, []);

  const handleUploadImage = (file) => {
    const fileFromData = new FormData();
    fileFromData.append("file", file);
    addNewCameraImage(params.company_uuid, params.camera_uuid, fileFromData)
      .then((res) => {
        if (res) {
          getCameraImage(
            params.company_uuid,
            params.camera_uuid,
            res.data.uuid,
          ).then((resp) => {
            if (res) {
              setCameraImage({
                ...cameraImage,
                uuid: res.data.uuid,
                url: window.URL.createObjectURL(resp.data),
              });
            }
          });
          showSnackbar("Uploaded Successfully", "success");
          getCameraImagePolygons(res.data.uuid);
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data.message === "Camera not found"
        ) {
          showSnackbar(AWAITING_SYNC_MSG, "warning");
        }
      });
  };

  const handleUploadEditImage = (file) => {
    const fileForm = new FormData();
    fileForm.append("file", file);
    editCameraImage(
      params.company_uuid,
      params.camera_uuid,
      cameraImage.uuid,
      fileForm,
    ).then((res) => {
      if (res) {
        showSnackbar("Updated Successfully", "success");
        getCameraImage(
          params.company_uuid,
          params.camera_uuid,
          res.data.uuid,
        ).then((resp) => {
          if (res) {
            setCameraImage({
              ...cameraImage,
              uuid: res.data.uuid,
              url: window.URL.createObjectURL(resp.data),
            });
          }
        });
        getCameraImagePolygons(res.data.uuid);
      }
    });
  };

  const handleRaiseTestIncident = (camera) => {
      const { uuid , company_uuid , site_uuid, name } = camera;
      raiseIncident(company_uuid, site_uuid, uuid, {site_name: location.state.site_name, camera_name: name}).then((res) => {
        res && showSnackbar("Incident Raised Successfully", "success");
      });
    };
  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: location.state.company_name,
              path: location.state?.prevCompanyLocation,
              state: location.state,
            },
            {
              name: location.state.site_name,
              path: location.state?.prevSiteLocation,
              state: location.state,
            },
          ],
          current: camera && camera?.name,
        }}
      />
      <Card variant="outlined">
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Raise a test incident</Typography>
            <Typography variant="body2">Test incident with a test image will be raised and appear in the customer dashboard</Typography>
            <Typography variant="body1"  sx={{ marginBottom: 1, color: 'warning.main' }}>Warning: it will cause notifications if any are configured</Typography>
          </Grid>
          <Grid item xs={4} display={"flex"} justifyContent={"end"} >
            <Button
              variant="contained"
              color="error"
              onClick={() => handleRaiseTestIncident(camera)}
              data-testid="raise_incident_btn"
            >
              Raise Test Incident
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
      {camera && (
        <Card variant="outlined">
          <CardHeader
            title="Camera Details"
            subheader="Details about this camera"
          />
          <CardContent>
            <Button
              variant={"outlined"}
              onClick={() =>
                navigate(
                  `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}/edit`,
                )
              }
            >
              Edit
            </Button>
          </CardContent>
          <CardContent>
            <CameraDetailsCard camera={camera} />
          </CardContent>
        </Card>
      )}

      {camera && (
        <Card variant="outlined">
          <CardHeader
            title="Camera Connections"
            subheader="List of camera connection"
          />
          <CardContent>
            {!camera.camera_connection && (
              <Button
                variant={"outlined"}
                onClick={() =>
                  navigate(
                    `/company/${params.company_uuid}/site/${params.site_uuid}/camera/${params.camera_uuid}/connection/add`,
                  )
                }
                data-testid="addConnectionBtn"
              >
                Add
              </Button>
            )}
          </CardContent>
          <CardContent>
            <CameraConnectionList
              camera={camera}
              getCameraDetailsRequest={getCameraDetailsRequest}
              location_state_details={location.state}
              camera_details={{
                camera_name: camera?.name,
                prevCameraLocation: location.pathname,
              }}
            />
          </CardContent>
        </Card>
      )}
      <Card variant="outlined">
        <CardHeader
          title="Camera Settings Overrides"
          subheader="List of camera settings overrides"
        />
        <CardContent>
          {defaultCameraSettings && cameraOverrides && (
            <CameraOverrides
              cameraSettings={cameraSettings}
              overrides={cameraOverrides}
              defaultCameraSettings={defaultCameraSettings}
              companyCameraOverrides={companyCameraOverrides}
              sitesOverrides={sitesOverrides}
              getCameraOverridesRequest={getCameraOverridesRequest}
            />
          )}
        </CardContent>
      </Card>

      <Card variant="outlined">
        <CardHeader
          title="Camera Detection Zone"
          subheader="Upload a reference image for this camera, and define detection zones for it"
        />
        <CardContent>
          {!cameraImage?.uuid && (
            <Button variant={"outlined"} component="label">
              Add
              <input
                hidden
                onChange={(e) => {
                  if (e.target.files) {
                    handleUploadImage(e.target.files[0]);
                    imageRef.current.value = "";
                  }
                }}
                accept="image/png, image/jpeg"
                type="file"
                ref={imageRef}
              />
            </Button>
          )}
        </CardContent>
        <CardContent>
          <Box pb={2}>
            {!cameraImage?.uuid ? (
              `No Image Found`
            ) : (
              <ImageTable
                image_uuid={cameraImage.uuid}
                image={cameraImage.url}
                polygonsNumber={polygons}
                location_state_details={location.state}
                camera_details={{
                  camera_name: camera?.name,
                  prevCameraLocation: location.pathname,
                }}
                getCameraImageRequest={getCameraImageRequest}
                handleUploadEditImage={handleUploadEditImage}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
