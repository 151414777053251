import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { Button, Grid, TextField, Tooltip, FormControlLabel, Checkbox } from "@mui/material";
import MUISelect from "../Controls/Select";
import { conditions, period_interval, rule_types } from "../../utilis/enum";
import { SLEEP_PERIOD_NOTE } from "../../utilis/shared";
import { OrgStructureNode } from "../../interfaces/OrgStructure";
import { RuleFormValue } from "../../interfaces/Notifications";

interface AddRuleFormProps {
  nodesList: OrgStructureNode[],
  handleSubmitRule: (values: RuleFormValue) => void,
  ruleTypes: string[]
}

export default function AddRuleForm({
  nodesList,
  handleSubmitRule,
  ruleTypes,
}: AddRuleFormProps) {
  const navigate = useNavigate();
  const schema = yup.object().shape({
    type: yup.string().required("Required"),
    node_uuid: yup.string().required("Required"),
    target_incident_count: yup.mixed().when("type", (type) => {
      if (type === rule_types.RULE_INCIDENT_CREATED_COUNT) {
        return yup.mixed().required("Required");
      }
    }),
    target_open_duration_sec: yup.mixed().when("type", (type) => {
      if (type === rule_types.RULE_INCIDENT_OPEN_DURATION) {
        return yup.mixed().required("Required");
      }
    }),
    evaluation_period_sec: yup.mixed().when("type", (type) => {
      if (type === rule_types.RULE_INCIDENT_CREATED_COUNT) {
        return yup.mixed().required("Required");
      }
    }),
    sleep_period_sec: yup.mixed().required("Required"),
    condition: yup.string().required("Required"),
    filter_acknowledged: yup.boolean().when("type", (type) => {
      if (type === rule_types.RULE_INCIDENT_OPEN_DURATION) {
        return yup.boolean().required("Required");
      }}),
  });

  return (
    <Formik
      initialValues={{
        type: "",
        node_uuid: "",
        target_incident_count: "",
        target_open_duration_sec: "",
        target_open_duration_sec_type: 1,
        evaluation_period_sec_type: 1,
        evaluation_period_sec: "",
        sleep_period_sec_type: 1,
        sleep_period_sec: "",
        condition: "",
        filter_acknowledged: false,
      }}
      validationSchema={schema}
      onSubmit={(values: any) => handleSubmitRule(values)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }: any) => (
        <form onSubmit={handleSubmit}>
          <MUISelect
            error={errors.type && touched.type && errors.type}
            value={values.type}
            label={"Type"}
            required
            name="type"
            handleChange={handleChange}
            handleBlur={handleBlur}
            list={ruleTypes}
            labelId={"label-type"}
            id={"select-type"}
            styles={{ mt: 2 }}
            dataTestid="rule_type"
          />
          <MUISelect
            error={errors.node_uuid && touched.node_uuid && errors.node_uuid}
            value={values.node_uuid}
            label={"Node"}
            required
            name="node_uuid"
            handleChange={handleChange}
            handleBlur={handleBlur}
            keyItem="uuid"
            keyValue="name"
            list={nodesList}
            labelId={"label-node_uuid"}
            id={"select-node_uuid"}
            styles={{ mt: 2 }}
            dataTestid="node"
            renderValue={() => {
              const node = nodesList?.find(
                (node) => node.uuid === values.node_uuid,
              );
              return <>{node?.name}</>;
            }}
          />
          {values.type === rule_types.RULE_INCIDENT_CREATED_COUNT && (
            <TextField
              type="number"
              id="target_incident_count"
              label="Target incident count*"
              fullWidth
              margin="normal"
              name="target_incident_count"
              error={Boolean(
                errors.target_incident_count && touched.target_incident_count,
              )}
              helperText={
                errors.target_incident_count &&
                touched.target_incident_count &&
                errors.target_incident_count
              }
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.target_incident_count}
              size="small"
              inputProps={{
                min: 0,
              }}
            />
          )}
          {values.type === rule_types.RULE_INCIDENT_CREATED_COUNT && (
            <Grid container spacing={2} sx={{ pt: 2, pb: 1 }}>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  id="evaluation_period_sec"
                  label="Evaluation period*"
                  fullWidth
                  name="evaluation_period_sec"
                  error={Boolean(
                    errors.evaluation_period_sec &&
                    touched.evaluation_period_sec,
                  )}
                  helperText={
                    errors.evaluation_period_sec &&
                    touched.evaluation_period_sec &&
                    errors.evaluation_period_sec
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.evaluation_period_sec}
                  inputProps={{
                    min: 0,
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <MUISelect
                  value={values.evaluation_period_sec_type}
                  label={"Interval"}
                  required
                  name="evaluation_period_sec_type"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  keyItem="id"
                  keyValue="label"
                  list={period_interval}
                  labelId={"label-evaluation_period_sec_type"}
                  id={"select-evaluation_period_sec_type"}
                  renderValue={() => {
                    const interval = period_interval?.find(
                      (interval) =>
                        interval.id === values.evaluation_period_sec_type,
                    );
                    return <>{interval?.label}</>;
                  }}
                />
              </Grid>
            </Grid>
          )}
          {values.type === rule_types.RULE_INCIDENT_OPEN_DURATION && (
            <Grid container spacing={2} sx={{ pt: 2, pb: 1 }}>
              <Grid item xs={6}>
                <TextField
                  type="number"
                  id="target_open_duration_sec"
                  label="Target open duration*"
                  fullWidth
                  name="target_open_duration_sec"
                  error={Boolean(
                    errors.target_open_duration_sec &&
                    touched.target_open_duration_sec,
                  )}
                  helperText={
                    errors.target_open_duration_sec &&
                    touched.target_open_duration_sec &&
                    errors.target_open_duration_sec
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.target_open_duration_sec}
                  inputProps={{
                    min: 0,
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <MUISelect
                  value={values.target_open_duration_sec_type}
                  label={"Interval"}
                  required
                  name="target_open_duration_sec_type"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  keyItem="id"
                  keyValue="label"
                  list={period_interval}
                  labelId={"target_open_duration_sec_type"}
                  id={"target_open_duration_sec_type"}
                  renderValue={() => {
                    const interval = period_interval?.find(
                      (interval) =>
                        interval.id === values.target_open_duration_sec_type,
                    );
                    return <>{interval?.label}</>;
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={2} sx={{ pt: 2, pb: 1 }}>
            <Grid item xs={6}>
              <Tooltip
                title={`${values.type ? (values.type === rule_types.RULE_INCIDENT_CREATED_COUNT ? `${SLEEP_PERIOD_NOTE} per camera` : `${SLEEP_PERIOD_NOTE} per incident`) : ""}`}
                placement="top"
              >
                <TextField
                  type="number"
                  id="sleep_period_sec"
                  label="Sleep period*"
                  fullWidth
                  name="sleep_period_sec"
                  error={Boolean(
                    errors.sleep_period_sec && touched.sleep_period_sec,
                  )}
                  helperText={
                    errors.sleep_period_sec &&
                    touched.sleep_period_sec &&
                    errors.sleep_period_sec
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sleep_period_sec}
                  inputProps={{
                    min: 0,
                  }}
                  size="small"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                value={values.sleep_period_sec_type}
                label={"Interval"}
                required
                name="sleep_period_sec_type"
                handleChange={handleChange}
                handleBlur={handleBlur}
                keyItem="id"
                keyValue="label"
                list={period_interval}
                labelId={"label-sleep_period_sec_type"}
                id={"select-sleep_period_sec_type"}
                renderValue={() => {
                  const interval = period_interval?.find(
                    (interval) => interval.id === values.sleep_period_sec_type,
                  );
                  return <>{interval?.label}</>;
                }}
              />
            </Grid>
          </Grid>

          <MUISelect
            error={errors.condition && touched.condition && errors.condition}
            value={values.condition}
            label={"Condition"}
            required
            name="condition"
            handleChange={handleChange}
            handleBlur={handleBlur}
            list={Object.values(conditions)}
            labelId={"label-condition"}
            id={"select-condition"}
            styles={{ mt: 2 }}
            renderValue={() => values.condition}
            dataTestid="condition"
          />
          {values.type === rule_types.RULE_INCIDENT_OPEN_DURATION && (
          <FormControlLabel
            control={
              <Checkbox
                checked={values.filter_acknowledged}
                name="filter_acknowledged"
                onChange={handleChange}
              />
            }
            label="Filter acknowledged incidents?"
          />
          )}
          <Grid container spacing={1} mt={3}>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type="submit"
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant={"contained"}
                onClick={() => navigate(-1)}
                color="error"
                sx={{ width: "100%" }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}
