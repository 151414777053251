/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { IconButton, Switch, TableCell, TableRow } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../Table";
import DeleteNotificationRule from "./DeleteNotificationRule";
import {
  activateRule,
  deactivateRule,
} from "../../apis/v1/Notifications/Rules";
import { NotificationRule } from "../../interfaces/Notifications";
import { RouterHistoryState } from "../../interfaces/RouterHistoryState";

interface NotificationsRulesTableProps {
  company_uuid: string;
  currentRouteState: RouterHistoryState;
  renderedRules: NotificationRule[];
  root_node_uuid: string;
  originalRules: NotificationRule[];
  setRenderedRules: React.Dispatch<NotificationRule[]>;
  setOriginalRules: React.Dispatch<NotificationRule[]>;
}

export default function NotificationsRulesTable({
  company_uuid,
  currentRouteState,
  renderedRules,
  setRenderedRules,
  root_node_uuid,
  setOriginalRules,
  originalRules,
}: NotificationsRulesTableProps) {
  const navigate = useNavigate();

  const handleChangeStatus = (rule_uuid, is_active) => {
    if (is_active) {
      deactivateRule(company_uuid, rule_uuid).then((res) => {
        if (res) {
          setRenderedRules(
            renderedRules.map((rule) => {
              if (rule.uuid === rule_uuid) {
                return {
                  ...rule,
                  is_active: false,
                };
              }
              return rule;
            }),
          );
          setOriginalRules(
            originalRules.map((rule) => {
              if (rule.uuid === rule_uuid) {
                return {
                  ...rule,
                  is_active: false,
                };
              }
              return rule;
            }),
          );
        }
      });
    } else {
      activateRule(company_uuid, rule_uuid).then((res) => {
        if (res) {
          setRenderedRules(
            renderedRules.map((rule) => {
              if (rule.uuid === rule_uuid) {
                return {
                  ...rule,
                  is_active: true,
                };
              }
              return rule;
            }),
          );
          setOriginalRules(
            originalRules.map((rule) => {
              if (rule.uuid === rule_uuid) {
                return {
                  ...rule,
                  is_active: true,
                };
              }
              return rule;
            }),
          );
        }
      });
    }
  };

  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "node_name",
          name: "Node name",
        },
        {
          id: "node_type",
          name: "Node type",
        },
        {
          id: "rule_type",
          name: "Rule type",
        },
        {
          id: "integration_type",
          name: "Integrations type",
        },
        {
          id: "is_active",
          name: "Active?",
        },
        {
          id: "condition",
          name: "Condition",
        },
        {
          id: "target_incident_count",
          name: "Target incidents count",
        },
        {
          id: "evaluation_period",
          name: "Evaluation period (seconds)",
        },
        {
          id: "target_open_duration",
          name: "Target open duration (seconds)",
        },
        {
          id: "sleep_period",
          name: "Sleep period (seconds)",
        },
        {
          id: "filter_acknowledged",
          name: "Filter acknowledged",
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={renderedRules}
      tableBodyRow={(row: any) => {
        return (
          <TableRow key={row?.uuid}>
            <TableCell>{row?.node?.name}</TableCell>
            <TableCell>{row?.node?.type}</TableCell>
            <TableCell>{row?.type}</TableCell>
            <TableCell>
              {row?.integrations?.types?.join(" , ") || "-"}
            </TableCell>
            <TableCell>
              {row?.is_active ? "Yes" : "No"}
              <Switch
                checked={row?.is_active}
                onChange={() => handleChangeStatus(row?.uuid, row?.is_active)}
                aria-label="switch"
                data-testid="switch"
                size="small"
              />
            </TableCell>
            <TableCell>{row?.condition}</TableCell>
            <TableCell>{row?.target_incident_count || "-"}</TableCell>
            <TableCell>{row?.evaluation_period || "-"}</TableCell>
            <TableCell>{row?.target_open_duration || "-"}</TableCell>
            <TableCell>{row?.sleep_period}</TableCell>
            <TableCell>{row?.filter_acknowledged !== null ? (row?.filter_acknowledged ? "Yes" : "No") : "-"}</TableCell>
            <TableCell align="right">
              <IconButton
                size="small"
                onClick={() =>
                  navigate(
                    `/company/${company_uuid}/root_node/${root_node_uuid}/notifications/rule/${row?.uuid}`,
                    {
                      state: {
                        ...currentRouteState,
                      },
                    },
                  )
                }
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() =>
                  navigate(
                    `/company/${company_uuid}/root_node/${root_node_uuid}/notifications/rule/${row?.uuid}/edit`,
                    {
                      state: {
                        ...currentRouteState,
                      },
                    },
                  )
                }
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <DeleteNotificationRule
                rule_uuid={row?.uuid}
                company_uuid={company_uuid}
                rules={renderedRules}
                setRules={setRenderedRules}
                originalRules={originalRules}
                setOriginalRules={setOriginalRules}
              />
            </TableCell>
          </TableRow>
        );
      }}
    />
  );
}
