import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import MUITable from "../../../Table";
import DeleteCamera from "./DeleteCamera";
import {
  ACTIVATED_CONNECTION_TOOLTIP,
  DEACTIVATE_CONNECTION_NOTE,
} from "../../../../utilis/shared";

export default function CameraListTable({
  cameras,
  company_details,
  site_details,
  onCameraDeleted,
  activateConnectionRequest,
  deactivateConnectionRequest,
}) {
  const navigate = useNavigate();
  const renderActiveStatusText = (connection) => {
    return connection?.is_active ? "Active" : "Deactive";
  };

  const renderConnectionAction = (camera) => {
    const { uuid, camera_connection , company_uuid , site_uuid } = camera;
    return camera_connection ? (
      <Tooltip
        title={camera_connection?.is_active ? DEACTIVATE_CONNECTION_NOTE : ""}
        placement="top"
      >
        <span>
          <Button
            onClick={() =>
              navigate(
                `/company/${company_uuid}/site/${site_uuid}/camera/${uuid}/connection/${camera_connection?.uuid}/edit`,
              )
            }
            disabled={camera_connection?.is_active}
            data-testid="edit-connection-btn"
          >
            Edit connection
          </Button>
        </span>
      </Tooltip>
    ) : (
      <Button
        onClick={() =>
          navigate(
            `/company/${company_uuid}/site/${site_uuid}/camera/${uuid}/connection/add`,
          )
        }
        data-testid="add-connection-btn"
      >
        Add Connection
      </Button>
    );
  };

  const renderConnectionActivitySwitch = (camera) => {
    const { camera_connection } = camera;
    return camera_connection ? (
      <>
        {renderActiveStatusText(camera_connection)}
        <Switch
          checked={camera_connection?.is_active}
          onChange={() =>
            camera_connection?.is_active
              ? deactivateConnectionRequest(camera)
              : activateConnectionRequest(camera)
          }
          aria-label="switch"
          size="small"
        />
      </>
    ) : (
      <>-</>
    );
  };


  return (
    <MUITable
      tableHeaderCells={[
        {
          id: "name",
          name: "Name",
        },
        {
          id: "services",
          name: "Services",
        },
        {
          id: "connection",
          name: "Connection",
        },
        {
          id: "connection_status",
          name: "Connection Status",
          tooltip_title: ACTIVATED_CONNECTION_TOOLTIP,
        },
        {
          id: "actions",
          name: "",
        },
      ]}
      hasPagination={true}
      data={cameras}
      tableBodyRow={(row) => (
        <TableRow key={row?.uuid} data-testid="camera-row">
          <TableCell>{row?.name}</TableCell>
          <TableCell>
            {row?.services?.length > 0
              ? row?.services.map((service) => service?.name).join(", ")
              : "-"}
          </TableCell>
          <TableCell>{renderConnectionAction(row)}</TableCell>
          <TableCell>{renderConnectionActivitySwitch(row)}</TableCell>
          <TableCell align="right">
            <IconButton
              size="small"
              data-testid="viewCameraDetails"
              onClick={() =>
                navigate(
                  `/camera/${row.uuid}/company/${row.company_uuid}/site/${row.site_uuid}`,
                  {
                    state: {
                      ...company_details,
                      ...site_details,
                    },
                  },
                )
              }
            >
              <VisibilityIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              data-testid="editBtn"
              onClick={() =>
                navigate(
                  `/company/${row.company_uuid}/site/${row.site_uuid}/camera/${row.uuid}/edit`,
                )
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <DeleteCamera camera={row} onCameraDeleted={onCameraDeleted} />
          </TableCell>
        </TableRow>
      )}
    />
  );
}
