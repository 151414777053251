export const insertKeyword = (text_to_add, field_id) => {
  let textarea: any = document.getElementById(field_id);
  let start_position = textarea.selectionStart;
  let end_position = textarea.selectionEnd;

  const textVal = `${textarea.value.substring(
    0,
    start_position,
  )}${` {${text_to_add}} `}${textarea.value.substring(
    end_position,
    textarea.value.length,
  )}`;
  textarea.focus();
  return textVal;
};
