/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import PagesHeader from "../../components/PagesHeader";
import { getCompanyRootNodes } from "../../apis/v1/OrgStructure";
import { editRule, getRule } from "../../apis/v1/Notifications/Rules";
import EditRuleForm from "../../components/NotificationsRules/EditRuleForm";
import {
  getFlattenChildrenNodesList,
  resetList,
} from "../../utilis/format-child-nodes";
import { showSnackbar } from "../../utilis/snackbar";
import { period_interval, rule_types } from "../../utilis/enum";
import MUIBreadcrumb from "../../components/Breadcrumb";
import { CompanyRule } from "../../interfaces/Notifications";

export default function EditRule() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [nodes, setNodes] = useState([]);
  const [rule, setRule] = useState<CompanyRule>();

  useEffect(() => {
    resetList();
    getRule(params.company_uuid, params.rule_uuid).then((res) => {
      if (res) {
        setRule(res.data);
      }
    });
    getCompanyRootNodes(params.company_uuid, params.root_node_uuid).then(
      (res) => {
        const nodes = getFlattenChildrenNodesList(res.data);
        setNodes(nodes);
      },
    );

    return () => {
      resetList();
    };
  }, []);

  const calculatePeriodSec = (value, type) => {
    const interval = period_interval[type - 1].value;
    return value * interval;
  };

  const handleSubmitRule = (values) => {
    const data = {
      rule_data: {
        node_uuid: values.node_uuid,
        sleep_period_sec: calculatePeriodSec(values.sleep_period_sec, values.sleep_period_sec_type),
        condition: values.condition,
        target_incident_count:
          values.type === rule_types.RULE_INCIDENT_CREATED_COUNT
            ? values.target_incident_count
            : null,
        evaluation_period_sec:
          values.type === rule_types.RULE_INCIDENT_CREATED_COUNT
            ? calculatePeriodSec(values.evaluation_period_sec, values.evaluation_period_sec_type)
            : null,
        target_open_duration_sec:
          values.type === rule_types.RULE_INCIDENT_OPEN_DURATION
            ? calculatePeriodSec(values.target_open_duration_sec, values.target_open_duration_sec_type)
            : null,
        filter_acknowledged: 
          values.type === rule_types.RULE_INCIDENT_OPEN_DURATION
            ? values.filter_acknowledged : null,
      },
    }
    editRule(params.company_uuid, params.rule_uuid, data).then((res) => {
      if (res) {
        showSnackbar("Rule updated successfully", "success");
        navigate(-1);
      }
    });
  };

  return (
    <>
      <MUIBreadcrumb
        data={{
          stackList: [
            {
              name: "Companies",
              path: "/companies",
            },
            {
              name: location.state.company_name,
              path: location.state?.prevCompanyLocation,
            },
          ],
          current: "Edit Notification Rule",
        }}
      />
      <PagesHeader title="Edit Notification Rule" />
      <p>Note: Some text goes here</p>
      <Grid container mt={3}>
        <Grid item xs={12} sm={6} md={4}>
          <EditRuleForm
            rule={rule}
            nodesList={nodes}
            handleSubmitRule={handleSubmitRule}
          />
        </Grid>
      </Grid>
    </>
  );
}
